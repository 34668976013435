.App {
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  .App-header {
    background-color: #a7afc0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .App-link {
    color: #61dafb;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#login-page #login-card,
#login-page #password-card {
  margin-top: 25%;

  .card {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: rgba(240, 245, 250, 0.44);
  }
}
