@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900;&display=swap");
body {
  font-family: "Inter", sans-serif;
  background-color: #e5e5e5;
}
a:hover {
  text-decoration: none;
}
.action_btn {
  border-color: #fd3398;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #fd3398ff;
  color: #fff;
  outline: 0;
  box-shadow: none !important;
  font-size: 13px;
}
.user-data-new-list{
  padding-top: 20px;
}
.lps-table {
  background-color: #fff;
}

.lps-modal-approvel1 {
  overflow-x: hidden;
  overflow-y: auto;  
}

.modal.show .lps-modal-approvel1 .modal-dialog {
  height: 100% !important;
}

.lps-table thead th,
.lps-table tbody td {
  color: #000000;
  border-color: #b3b3b3;
  padding: 15px 25px;
  vertical-align: middle;
}
.lps-table thead th {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  border-bottom: 1px solid #dee2e6;
  border-top: 0;
  padding: 20px 25px;
}
.lps-table tbody td {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  padding: 30px 25px;
}

.report-page-container td{
  text-transform: capitalize;
}

.lps-table .user-wrapper {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  display: inline-block;
}
.lps-table .user-wrapper svg,
.lps-table .user-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lps-link {
  font-weight: 500;
  color: #fd3398;
  cursor: pointer;
}
.link-black {
  color: #1a1a1a;
}
.link-black:hover {
  color: #fd3398;
}
.lps-table .lps-tr-inner .user-wrapper {
  height: 222px;
  width: 134px;
  border-radius: 0;
  margin-right: 0;
}
.lps-table tbody .lps-tr-inner td {
  border-top: 0;
}
.lps-table .lps-tr-inner {
  display: none;
}
.username-list {
  margin-bottom: 5px;
}
.lps-break-word {
  max-width: 174px;
}
.tr-ignore {
  opacity: 0.4;
}
.text_capitalize{
  text-transform: capitalize;
}

/** Modal css here **/
.lps-modal .modal-dialog {
  max-width: 680px;
}
.lps-modal .modal-content {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
.lps-modal .modal-body {
  padding: 78px 100px 83px 100px;
}
.lps-modal .modal-title {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 29px;
  color: #1a1a1a;
  font-weight: 400;
}
.lps-modal .modal-para {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #1a1a1a;
}
ul.post-list {
  padding-left: 67px;
  margin-top: 23px;
  margin-bottom: 35px;
  list-style: none;
}
.post-list li {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #1a1a1a;
  margin-bottom: 22px;
  position: relative;
}
.post-list li input {
  width: 15px;
  height: 15px;
  content: "";
  position: absolute;
  left: -41px;
  top: 15px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #000000;
}
.lps-btn-wrapper {
  text-align: right;
  margin-top: 40px;
}
.post-form .form-control {
  border-color: #838296;
  box-shadow: none;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #767676;
}
.post-form .form-control::placeholder,
.post-form .form-control::-webkit-input-placeholder,
.post-form .form-control::input-placeholder {
  color: #767676;
}
.post-form .btn {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #1a1a1a;
  padding: 7px 27px;
  border-radius: 0;
  margin-left: 20px;
}

.post-form .btn-primary,
.post-form .btn-primary:active {
  background: #fd3398 !important;
  border-color: #fd3398 !important;
}
.post-form .btn-secondary,
.post-form .btn-secondary:active {
  color: #1a1a1a !important;
  background: #e1e1e1 !important;
  border-color: #e1e1e1 !important;
}
.post-form .btn-primary:focus,
.post-form .btn-primary:active,
.post-form .btn-secondary:focus,
.post-form .btn-secondary:active {
  box-shadow: none !important;
  outline: 0;
}
.modal-backdrop.show {
  opacity: 0;
}
.report-page-container table td {
  vertical-align: top;
  padding-top: 12px;
}
.user-img-wrapper {
  width: 140px;
  height: 220px;
}
.user-img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.user-text-wrapper {
  max-width: 200px;
}
.user-data-list {
  padding-top: 50px;
}
.user-text-wrapper.post-descritpiton{
  margin-top: -23px;
}
.user-data-list-btns .user-data-new-td {
  padding-top: 80px;
}

.suggest-modal.lps-modal .modal-title {
  margin-bottom: 50px;
   
}
.suggest-modal.lps-modal .modal-para {
  font-weight: 400;
  font-size: 18px;
 
  margin-bottom: 15px;
}
.suggest-para-bottom {
  margin-top: 35px;
}
.suggest-para-bottom p{
  margin-bottom: 33px;
  font-weight: 400;
}
.lps-modal.suggest-modal .modal-body {
  padding: 78px 120px 83px 120px;
}
.input-counter {
  position: relative;
}
.input-counter input {
  padding-right: 50px;
}
.counter-block {
  font-size: 14px;
  position: absolute;
  bottom:7px;
  right: 8px;
  background: #fff;
}
.action-less-btns .action_btn {
  display: inline-block;
  margin: 4px 5px;
  padding: 5px 10px;
  font-size: 13px;
 
}
 
.action-less-btns {
  min-width: 290px;
}
.text-initail {
  text-transform: initial !important;
}

@media only screen and (max-width: 1440px) {
.lps-modal .modal-body {
  padding: 55px 100px 55px 100px;
}
}

@media only screen and (max-width: 991px) {
  .lps-table tbody td {
    font-size: 14px;
    padding: 30px 20px;
  }
  .lps-table thead th {
    font-size: 16px;
    padding: 20px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .lps-modal .modal-body, .lps-modal.suggest-modal .modal-body{
    padding: 40px 45px 50px;
  }
  .lps-modal .modal-para {
    font-size: 16px;
  }
  .post-list li {
    font-size: 15px;
  }
  .post-form .form-control {
    font-size: 15px;
  }
  .post-form .btn {
    font-size: 15px;
    padding: 7px 25px;
    margin-left: 15px;
  }
  .lps-modal .modal-title {
    font-size: 22px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 580px) {
  .lps-modal .modal-body {
    padding: 30px 20px 40px;
  }
  ul.post-list {
    padding-left: 41px;
  }
  .modal-backdrop.show {
    opacity: 0.5;
  }
}
.show {
    display: block;
}

.post-list li input {
  top: 7px;
}

.textareaCountWrapper {
  position: relative;
  margin-top: 10px;
  margin-left: 44px;
  margin-bottom: 15px;
}
ul.post-list {
  margin-bottom: 55px;
}
.post-form .post-list .form-control {
  border-color: #838296;
  box-shadow: none;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #767676;
  padding-right: 7px;
  border-bottom: 1px solid #838296;
  width: 100%;
  border-radius: 0;
  height: 30px;
  font-size: 14px;
  position: inherit;
}
.textareaCountWrapper .textRange {
  font-size: 14px;
  line-height: 18px;
  color: #1a1a1a;
  display: block;
  text-align: right;
  background-color: #fff;
  padding: 2px 5px;
  position: absolute;
  top: 11px;
  right: 44px;
  border: 0;
  display: block;
  margin-left: auto;
  width: 68px;
  height: 25px;
}