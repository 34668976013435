//colors

$light-theme: #e0e0eb;
$primary-text-color: #fd3398;
$side-nav-text: #202125;
$primary-color: #fd3398;
$primary-icn-bg: rgba(255, 255, 255, 0.25);

// Fonts
$font-family-base: 'Montserrat', sans-serif;
$font-size-base: 0.9375rem !default; // Assumes the browser default, typically `16px`
