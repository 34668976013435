body {
  background: #fff;
  color: #141414;
  font-family: $font-family-base !important;
}
#login-card {
  p {
    font-size: 0.875rem;
    font-weight: 500;
    color: #3a3a3a;
  }
  label {
    font-size: 0.95rem;
    color: $primary-color;
  }
}
/**** Main content css here ****/
.wraper_content {
  .calender_block {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    .select_box {
      .custom-select {
        min-width: 200px;
      }
    }
  }
  .filter_block {
    display: inline-block;
    margin-right: 15px;
    .list_filter {
      margin: 0;
      padding: 0px 5px;

      border-radius: 4px;

      .list_link {
        padding: 6px 8px;
        display: block;
        font-size: 15px;
        color: $filter_link;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
          color: $primary-color;
          text-decoration: none;
        }
      }
      .active {
        color: $primary-color;
      }
    }
  }
  .cal_block {
    display: inline-block;
    border: 1px solid $form_control_border;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0px 3px 6px 2px #b44423;
      -webkit-box-shadow: 0px 3px 6px 2px #be4825;
      -moz-box-shadow: 0px 3px 6px 2px #c04826;
      border-color: #b64524;
    }
    .str_end_date {
      border: none;
      width: 92px;
      display: inline-block;
      color: $filter_link;
      font-size: 15px;
      padding-right: 0;
      padding-left: 0;
    }
    .gj-datepicker [role='right-icon'] {
      display: none;
    }
    .gj-datepicker.gj-datepicker-bootstrap.gj-unselectable.input-group {
      display: inline;
    }
    .date_seprator {
      img {
        margin-bottom: 3px;
        margin-right: 3px;
      }
    }
  }
}
.gj-picker-bootstrap table tr td.selected.gj-cursor-pointer div {
  color: $white;
  background-color: $mehron-600 !important;
  border: 2px solid $white !important;
  border-radius: 0px !important;
  line-height: 28px !important;
}
.gj-picker-bootstrap .selected {
  color: #888;
  background-color: $mehron-600 !important;
  padding: 5px !important;
  vertical-align: middle;
  font-size: 12px !important;
}
.gj-picker-bootstrap div[role='navigator'] {
  color: $cal_month;
  font-weight: 400;
}
.tile {
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  -webkit-box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.user-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.user-img .icon {
  font-size: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.user-img {
  line-height: 40px;
  i {
    font-size: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
.text-truncate {
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  margin-bottom: -3px;
}
.custom__scroll {
  /*max-height: 60vh;*/
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 0.2em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}
.header-primary {
  color: #fff;
  background: #e24e4e00 linear-gradient(180deg, #ff0101, #dc6c7040) repeat-x;
}
.custom__message .MuiChip-label {
  white-space: initial !important;
}
.pointer-none {
  pointer-events: none;
}
.disabled {
  opacity: 0.6;
}
*:focus {
  outline: none !important;
}
.font10 {
  font-size: 10px;
}
.height100 {
  height: 100vh;
}
.user-img-avatar {
  padding-left: 41% !important;
}
/**** // Main content css here ****/
.MuiPopover-paper {
  margin-top: 40px;
  max-height: 30vh !important;
}
/**** // Login form css here ****/
.limiter {
  width: 100%;
  margin: 0 auto;
}
.limiter .container-login100 {
  width: 100%;
  background-color: #e1e1e1;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 1;
}
#wrap {
  width: 100%;
}

@media screen and (min-width: 1200px) {
  .lps_container {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .lps_container {
    max-width: 1140px;
    width: 100%;
  }
}

.lps_container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  width: 1000px;
  height: 100%;
  background-color: #e1e1e1;
}
.on_boarding_wrp.on_boardingNChng {
  padding-left: 35px;
  padding-right: 35px;
}
.lps_flx_vm_jc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.lps_bg_secondary {
  background-color: #1a1a1a !important;
}

.on_boarding_wrp {
  padding-left: 28px;
  padding-right: 28px;
}

.lps_bg_txt_white {
  height: 100%;
  background-color: #fd3398;
}
.lps_form_wrp {
  width: 100%;
}
.text_center {
  text-align: center !important;
}
.lps_logo_center .logo {
  margin-bottom: 10px;
  display: inline-block;
}
.lps_logo_center .header__logo {
  height: 100px;
}
.header__logo {
  height: 50px;
}
.lps_logo_center .logo h5 {
  -webkit-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  transform: translateY(-40px);
  margin-bottom: 0;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 1.42px;
  font-weight: 500;
}
.lps_fields {
  margin: 50px 0 25px 0;
}
.form_group_modify {
  margin-bottom: 15px;
}
.on_boarding_wrp .lps_form_wrp .input_modify {
  border-bottom-color: #fd3398;
}

.lps_bg_txt_white .input_modify {
  background-color: transparent;
  border-bottom-color: #ffffff;
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding-left: 10px;
  padding-right: 10px;
  color: #ffffff;
  height: 38px;
}

.input_modify {
  font-weight: 400;
  width: 100%;
  height: 52px;
  border-radius: 5px;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
  line-height: 1.33;
  display: block;
  background-color: #ffffff;
  color: #1a1a1a;
  border: 1px solid #a6a6a6;
  background-clip: padding-box;
  -webkit-transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.on_boarding_wrp .icn_passAbslt {
  position: absolute;
  top: 8px;
  right: 0;
}
.on_boarding_wrp .icn_passAbslt img {
  width: 25px;
}
.on_boarding_wrp .lps_form_wrp .pos_wrp {
  margin-top: 0;
}

.lps_form_wrp .pos_wrp {
  text-align: center;
  display: block;
  margin-top: 120px;
}
.theme_primary {
  color: #ffffff;
  background-color: #fd3398;
  border-color: #fd3398;
}

.theme_btn {
  min-width: 90px;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 7px 16px;
  font-size: 16px;
  line-height: 1.3;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  letter-spacing: 1.8px;
  transition: all, 0.4s, ease-in-out;
}

a {
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
p:last-child {
  margin-bottom: 0;
}

.mt_25 {
  margin-top: 25px;
}

.text_white {
  color: #ffffff !important;
}

.lps_link {
  display: inline-block;
  color: #fd3398 !important;
}

.link_underline,
.link_underline:hover {
  text-decoration: underline !important;
  word-break: break-all;
}

.aboot_content_userdetails {
  word-break: break-all;
}

p {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 15px;
}

.lps_cont_check {
  color: #ffffff;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

.lps_cont_check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lps_cont_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.lps_cont_check {
  margin-top: 30px;
}

.lps_cont_check .lps_Checkmark:after {
  left: 7px;
  top: 3px;
}

.on_boarding_wrp .lps_Checkmark {
  background-color: transparent;
  border: 1px solid #fd3398;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
}

.lps_Checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.on_boarding_wrp .lps_form_wrp .input_modify.active {
  border-bottom-color: #ffffff !important;
}
.on_boarding_wrp .lps_cont_check input:checked ~ .lps_Checkmark:after {
  border-color: #1a1a1a;
}

.lps_cont_check input:checked ~ .lps_Checkmark:after {
  display: block;
}
.on_boarding_wrp .lps_cont_check .lps_Checkmark:after {
  left: 5px;
  top: 0;
  width: 8px;
  height: 15px;
}

.lps_Checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.on_boarding_wrp .lps_form_wrp .pos_wrp {
  margin-top: 0;
}

.lps_form_wrp .pos_wrp {
  text-align: center;
  display: block;
  margin-top: 120px;
}

.lps_cont_check .lps_Checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #1a1a1a;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.lps_cont_check input:checked ~ .lps_Checkmark {
  background-color: #2196f3;
}

.on_boarding_wrp .lps_cont_check input:checked ~ .lps_Checkmark {
  background-color: #fd3398;
  transition: 0.3s ease-in-out;
}

.btn_block {
  width: 100%;
  display: block;
}

.theme_btn_rds25 {
  border-radius: 25px;
}

.text_uppercase {
  text-transform: uppercase !important;
}

.lps_pos_rltv {
  position: relative;
}

.mb20 {
  margin-bottom: 20px !important;
}

.text_right {
  text-align: right !important;
}

.link_white {
  color: #ffffff !important;
}

.on_boarding_wrp .lps_cont_check {
  color: #ffffff;
  font-size: 15px;
}

.img-container {
  .recipe-img {
    width: auto;
    left: auto;
    margin: auto;
    height: 200px;
    width: 250px;
    padding: 5px;
    border: solid 1px #eee;
    border-radius: 5px;
  }
}

.font-color {
  color: black !important;
}
.approval {
  width: 150px;
  margin: 15px 0 0;
}
.smalBtn button {
  width: 335px;
  margin: 0 auto;
}
@media screen and (max-width: 959px) {
  .smalBtn button {
    width: 100%;
  }
}

.srv-validation-message {
  color: #ff242b;
}
.sidenav-toggled img.header_logo_img {
  width: 43px;
}
.app-sidebar__toggle:hover {
  opacity: 1 !important;
  cursor: pointer;
}

.commonBtn {
  color: #fff;
  background: #fd3398;
  padding: 10px 24px;
  display: inline-block;
  border-radius: 5px;
  border: 2px solid #fd3398;
  transition: all ease 0.3s;
}

.loading {
  cursor: wait;
}

.commonBtn:hover {
  color: #fff;
}
.action_active_text {
  color: $primary-color;
}

.sugg-create-wrapper {
  position: relative;
  input.input_modify {
    padding-left: 20px !important;
  }
  .hash-tag {
    position: absolute;
    bottom: 24px;
    left: 28px;
    color: #757070;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    line-height: 1.33;
  }
  .MuiOutlinedInput-input {
    padding-left: 25px;
  }
  .MuiInputLabel-formControl {
    left: 10px;
    color: #757070;
  }
}
.mxBtnRight {
  max-width: 278px;
  margin-left: auto;
  text-align: left;
}

.filter {
  .button_text {
    text-transform: lowercase !important;
  }
}

.thumb_img_grid {
  .feature_fig {
    width: 185px;
    height: 200px;
    overflow: hidden;
    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .feature_item {
    display: inline-block;
    margin-right: 12px;
    margin-top: 18px;
  }
}

/*** approval modal ***/
.lps-modal-approvel {
  overflow-x: hidden;
  overflow-y: auto;
}
.lps-modal-approvel.lps-modal .modal-body {
  padding: 30px;
}
.lps-modal-approvel .lps-text-gray {
  color: #b3b3b3;
}
.lps-modal-approvel .modal-sub-title {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 20px;
}
.lps-modal-approvel .form-control:placeholder,
.lps-modal-approvel .form-control:-ms-input-placeholder,
.lps-modal-approvel .form-control::-webkit-input-placeholder {
  color: #767676;
}
.lps-modal-approvel .form-control {
  border-color: #838296;
}
.textarea-count-wrapper {
  position: relative;
}
.textarea-count-wrapper .textarea-input {
  border-color: #838296;
  border-radius: 0;
  border-bottom: 0;
  height: 150px;
}
.approval_about {
  word-break: break-all;
}
.textarea-count-wrapper {
  .textRange {
    font-size: 14px;
    line-height: 18px;
    color: #1a1a1a;
    display: block;
    text-align: right;
    background-color: #ffffff;
    padding: 10px;
    position: relative;
    top: 0px;
    border: 1px solid #838296;
    border-top: 0;
  }
}
.lps-modal-approvel .modal-para {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #1a1a1a;
  margin-top: 25px;
  margin-bottom: 20px;
}
.switch-wrapper .switch-label {
  margin-bottom: 0;
  margin-right: 15px;
  font-size: 18px;
  font-weight: 400;
}
.switch-wrapper {
  margin-bottom: 10px;
}
.thumb_img_grid .feature_fig {
  width: 99px;
  height: 99px;
  overflow: hidden;
  margin: 0;
}
.feature_fig img {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
}
.thumb_img_grid .feature_item {
  display: inline-block;
  margin-right: 13px;
}
.thumb_img_grid .feature_item:last-child {
  margin-right: 0;
}
.switch-wrapper .switch-inline {
  display: inline-block;
  margin-right: 40px;
}
.lps-link-daek {
  color: #1a1a1a;
  word-break: break-all;
}
@media screen and (min-width: 992px) {
  .lps-modal.lps-modal-approvel .modal-dialog {
    max-width: 90%;
    width: 100%;
  }
  .appovel-left-col {
    padding-right: 35px;
  }
  .col-md-8.appovel-border-left {
    padding-left: 50px;
    border-left: 1px solid #838296;
  }
  .lps-modal-approvel.lps-modal .modal-body {
    padding: 0 30px;
  }
  .approvel-content {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .lps-modal-approvel .lps-btn-wrapper {
    margin-bottom: 30px;
  }
}

.lps-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 26px;
}

.lps-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.lps-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #fd3398;
}

.lps-slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 1px;
  bottom: 1px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #fd3398;
}

input:checked + .lps-slider {
  background-color: #fd3398;
}

input:focus + .lps-slider {
  box-shadow: 0 0 1px #fd3398;
}

input:checked + .lps-slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
  background: #e1e1e1;
}

/* Rounded sliders */
.lps-slider.lps-round {
  border-radius: 34px;
}
.lps-slider.lps-round:before {
  border-radius: 50%;
}
.btn_drak_gray {
  background-color: rgb(189, 189, 189);
}
/*** approval modal ***/

// user action item  tab
.tabs-section .nav-pills .nav-link.active {
  color: #fff;
  background-color: #fd3398 !important;
}
.tabs-section .nav-pills .nav-link {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #000;
  padding: 15px 15px;
}
.tabs-section .nav-pills {
  margin-top: 15px;
}

.tabs-section .nav-pills .pills-tab {
  justify-content: space-between;
}
.tabs-section .nav-pills .nav-item {
  width: 50%;
  text-align: center;
}

.DateRangePicker__Month {
  color: #333;
  display: inline-block;
  margin: 0 20px;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 275px;
  background: #fff !important;
  z-index: 1 !important;
}
