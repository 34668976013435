@mixin btn-style($border-radius) {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: $border-radius;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@mixin btn-color-style($color, $background-color, $border-color) {
  color: $color;
  background-color: $background-color;
  border-color: $border-color;
}

.btn_block {
  margin-bottom: 20px;
}

.cs_btn {
  @include btn-style(0);
}

.cs_btn_rdus {
  @include btn-style(0.25rem);
}

.cs_primary {
  @include btn-color-style(#fff, $primary, $primary);
}

.cs_primary:hover {
  @include btn-color-style(#fff, $primary, $primary);
}

.cs_secondary {
  @include btn-color-style(#fff, #6c757d, #6c757d);
}

.cs_secondary:hover {
  @include btn-color-style(#fff, #5a6268, #545b62);
}

.cs_success {
  @include btn-color-style(#fff, #28a745, #28a745);
}

.cs_success:hover {
  @include btn-color-style(#fff, #218838, #1e7e34);
}

.cs_danger {
  @include btn-color-style(#fff, #dc3545, #dc3545);
}

.cs_danger:hover {
  @include btn-color-style(#fff, #c82333, #bd2130);
}

.cs_warning {
  @include btn-color-style(#212529, #ffc107, #ffc107);
}

.cs_warning:hover {
  @include btn-color-style(#212529, #e0a800, #d39e00);
}

.cs_info {
  @include btn-color-style(#fff, #17a2b8, #17a2b8);
}

.cs_info:hover {
  @include btn-color-style(#fff, #138496, #117a8b);
}

.cs_light {
  @include btn-color-style(#212529, #f8f9fa, #f8f9fa);
}

.cs_light:hover {
  @include btn-color-style(#212529, #e2e6ea, #dae0e5);
}

.cs_dark {
  @include btn-color-style(#fff, #343a40, #343a40);
}

.cs_dark:hover {
  @include btn-color-style(#212529, #e2e6ea, #dae0e5);
}

.cs_link {
  font-weight: 400;
  color: $primary;
  background-color: transparent;
}

.cs_link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

/*outline btn css*/
.cs_outline_primary {
  @include btn-color-style($primary, transparent, #007bff);
  background-image: none;
}

.cs_outline_primary:hover {
  @include btn-color-style(#fff, $primary, $primary);
}

.cs_outline_secondary {
  @include btn-color-style(#6c757d, transparent, #6c757d);
  background-image: none;
}

.cs_outline_secondary:hover {
  @include btn-color-style(#fff, #6c757d, #6c757d);
}

.cs_outline_success {
  @include btn-color-style(#28a745, transparent, #28a745);
  background-image: none;
}

.cs_outline_success:hover {
  @include btn-color-style(#fff, #28a745, #28a745);
}

.cs_outline_danger {
  @include btn-color-style(#dc3545, transparent, #dc3545);
  background-image: none;
}

.cs_outline_danger:hover {
  @include btn-color-style(#fff, #dc3545, #dc3545);
}

.cs_outline_warning {
  color: #ffc107;
  background: transparent none;
  border-color: #ffc107;
}

.cs_outline_warning:hover {
  @include btn-color-style(#212529, #ffc107, #ffc107);
}

.cs_outline_info {
  @include btn-color-style(#17a2b8, transparent, #17a2b8);
  background-image: none;
}

.cs_outline_info:hover {
  @include btn-color-style(#fff, #17a2b8, #17a2b8);
}

.cs_outline_light {
  color: #f8f9fa;
  background: transparent none;
  border-color: #f8f9fa;
}

.cs_outline_light:hover {
  @include btn-color-style(#212529, #f8f9fa, #f8f9fa);
}

.cs_outline_dark {
  @include btn-color-style(#343a40, transparent, #343a40);
}

.cs_outline_dark:hover {
  @include btn-color-style(#fff, #343a40, #343a40);
}

button:disabled {
  opacity: 0.6;
}
button {
  font-family: $font-family-base !important;
}
.mat-button[disabled][disabled][type="submit"] {
  color: $white;
}
.btn-primary {
  color: #fff;
  background: $primary-color linear-gradient(180deg, $primary-color, $primary-color)
    repeat-x;
  border-color: $primary-color;
}
.btn-slim {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
/* // outline btn css*/
