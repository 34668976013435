//colors

$primary: #fd3398;
$secondary: #262933;
$black: #202125 !default;
$white: #fff !default;
$gray: #4a4a4a !default;
$blue: #66afe9 !default;
$theme_univ: #16324f !default;
$theme_univ_hov: #224365 !default;
$theme_body_clr: #31323c !default;
$theme_danger: #ea4031 !default;
$blue-100: rgba(102, 175, 233, 0.35) !default;
$gray-100: #ccc;
$gray-400: #eee;
$black-150: rgba(0, 0, 0, 0.15);
$theme_link: #3e92cc;
$theme_link_hv: #2f3192;

$mehron: #850038;
$mehron-600: #9b2c59;
$mehron-300: rgba(233, 88, 46, 0.37);
$filter_link: #566975;
$form_control_border: #b3bbc1;
$cal_month: #2b3034;
$main_content_bg: #fefefe;
// Fonts
$font-family-base: 'Montserrat', sans-serif;
// stylelint-enable value-keyword-case
$font-size-base: 0.9375rem !default; // Assumes the browser default, typically `16px`
$-h1: 2.25rem;
$-h2: 2rem;
$-h3: 1.75rem;
$-h4: 1.5rem;
$-h5: 1.25rem;
$-h6: 1.125rem;
$para-font: 1rem;

// theme dark
$theme-gray: #5c616f;
$color-secondary: #2d323e;

//Font Family
$body-font-family: 'Montserrat', sans-serif;
$body-font-size: 16px;

// Font Weights
$ft_back_900: 900;
$ft_bold_700: 700;
$ft_semi_bold_600: 600;
$ft_medium_500: 500;
$ft_regular_400: 400;
$ft_light_300: 300;

// Transition
$transition: all 0.5s;


//login form
//colors

$black:    #000000 !default;
$white: #ffffff !default;
$blue:    #2d83e8 !default;
$gray-900: #526C79 !default;
$pink:   #F94F7A !default;
$yellow:  #FBF9F0 !default;
$gray-800: #555555 !default;

$brand-primary: #fd3398 !default;
$brand-secondary: #1a1a1a !default;
$brand-danger: #FF242B !default;
$brand-success: #17BE32 !default;
$brand-warning: #e7b000 !default;
$link-danger: #E8242A !default;

$brand-primary-100: #FEF1EB;
$brand-primary-200: #FFCCCC;
$brand-primary-300: #faccd4;
$brand-primary-400: #cfa2a9;
$brand-primary-500: #ffe8e8;
$brand-primary-600: #ea8fc4;

$bg_grayCCC: #E1E1E1;
$white-200: #f2f2f2;
$white-600: #E6E6E6;
$brand-red100: #999933;
$gray-600: #bfbfbf;
$gray-700: #4D4D4D;
$gray-800: #707070;
$gray-900: #8b8b8b;
$gray-1000: #838296;
$green-255: #03ff9a;
$screamin-green: #39ff67;
$blue-255: #5d00ff;

$input-border: #a6a6a6;
$black-o1: rgba(0, 0, 0, 0.125);

// applied colors:
$link-color:       $brand-primary !default;
$link-hover-color: darken($link-color, 10%) !default;
$brand-primary-hover: darken($brand-primary, 10%) !default;
$brand-secondary-hover: darken($brand-secondary, 10%) !default;
$brand-danger-hover: darken($brand-danger, 10%) !default;


$ft_Weight_100: 100;
$ft_Weight_200: 200;
$ft_Weight_300: 300;
$regular: 400;
$ft_Weight_MD: 500;
$ft_Weight_SM: 600;
$logo_font: 'Great Vibes', cursive;