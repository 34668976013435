//@extend-elements
//original selectors
//.app-sidebar__toggle:focus, .app-sidebar__toggle:hover

%extend_3 {
  color: $white;
  background-color: transparent;
  text-decoration: none;
}

body {
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.app-nav {
  padding-left: 0;
  list-style: none;
  display: flex;
  margin-bottom: 0;
  justify-content: flex-end;
  align-items: center;
}

.app-menu {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  margin-top: 5px;
  .app-menu__icon {
    margin-right: 20px;
  }
}

.sidebar-mini {
  .app-header {
    width: 100%;
    margin-left: inherit;
  }
}

.app-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: flex;
  z-index: 3;
  align-items: center;
  transition: left 0.5s ease, width 0.5s ease;
  border-bottom: 1px solid #cccccc;
  .app_header_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    transition: all 0.5s ease, width 0.5s ease;
  }
}
.app-content {
  margin-left: 280px;
  min-height: calc(100vh);
  padding: 45px 0 50px;
  background-color: #f5f5f5;
  transition: margin-left 0.3s ease;
  overflow: auto;
  // height: 100vh;
}
.app-header__logo {
  flex: 1 0 auto;
  color: #fff;
  padding: 0px 15px 0px 0px;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  img {
    width: 150px;
  }
  &:focus {
    text-decoration: none;
    color: $white;
  }
  &:hover {
    text-decoration: none;
    color: $white;
  }
}

.app-sidebar__toggle {
  padding: 0;
  font-family: fontAwesome;
  color: $white;
  line-height: 2.4;
  margin-left: 10px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
  &:before {
    content: "\f0c9";
    font-size: 21px;
    display: none;
  }
  &:focus {
    @extend %extend_3;
    opacity: 0.6;
  }
  &:hover {
    @extend %extend_3;
    opacity: 0.6;
  }
  span {
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 25px;
    border-radius: 10px;
  }
}
.app-nav__item {
  display: block;
  padding: 0 15px;
  line-height: 20px;
  color: $white;
  transition: background-color 0.3s ease;
  &:hover {
    background: $white;
    color: #f6f6f6;
  }
  &:focus {
    background: $white;
    color: #f6f6f6;
  }
}
.app-sidebar {
  padding-top: 70px;
  width: 280px;
  //overflow: auto;
  z-index: 10;
  transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.5s ease, width 0.5s ease;
  height: 100vh;
  float: right;
  //overflow: hidden;
  .app-sidebar_Heading {
    white-space: nowrap;
  }
  h4 {
    font-size: $-h4;
    font-weight: normal;
    margin: 0;
  }
  p {
    font-size: $para-font;
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 500;
  }
}
.app-sidebar::-webkit-scrollbar {
  width: 6px;
}
.app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}
.app-menu__item {
  position: relative;
  padding: 5px;
  font-size: 14px;
  border-left: 5px solid transparent;
  -webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  -o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  transition: border-left-color 0.3s ease, background-color 0.3s ease;
  margin: 0 10px 4px;
  border-radius: 4px;
  white-space: nowrap;
  word-break: keep-all;
  width: auto;
  display: block;
  img {
    margin-right: 15px;
  }
  .icons_blue {
    margin-right: 5px;
    width: 30px;
  }
  .icons_white {
    width: 30px;
  }
  &.active {
    text-decoration: none;
    outline: none;
    .icons_white {
      width: 36px;
    }
  }
  &:hover {
    text-decoration: none;
    outline: none;
    .icons_white {
      width: 36px;
    }
  }
  &:focus {
    text-decoration: none;
    outline: none;
    .icons_white {
      width: 36px;
    }
  }
}

.app-menu__icon {
  flex: 0 0 auto;
}

.app-menu__label {
  white-space: nowrap;
  flex: 1 1 auto;
}

.footer_content {
  width: 100%;
  float: left;
  text-align: center;
  padding: 15px;
  z-index: 1;
  border-top: 1px solid #e7e7e7;
  background: #f5f5f5;
  position: relative;
  p {
    margin-bottom: 0;
  }
}
.app-sidebar-mini__heading {
  display: none;
}
@media (min-width: 768px) {
  .sidebar-mini {
    &.sidenav-toggled {
      .sidebar-heading {
        visibility: hidden;
      }
      .case_acknowlst_reports {
        padding: 45px 30px 0 25px;
      }
      .app-sidebar__user-name {
        display: none;
      }
      .app-sidebar__user-designation {
        display: none;
      }
      .app-sidebar__user-avatar {
        width: 30px;
        height: 30px;
      }
      .app-content {
        margin-left: 75px;
      }
      .app-sidebar-mini__heading {
        display: block;
      }
      .app-sidebar_Heading {
        display: none;
      }
      .app-sidebar {
        left: 70px;
        width: 75px;
        //overflow: hidden;
        .app-menu {
          left: 70px;
          width: 100%;
          //overflow: hidden;
          .app-menu__item {
            border-left: 15px solid transparent;
            .app_menu__label {
              display: none;
            }
            &:hover {
              width: 230px;
              .app_menu__label {
                display: inline-block !important;
              }
            }
          }
        }
      }
      .app_logo {
        display: none;
      }
      .app-sidebar__toggle {
        padding: 0;
        .toogle_left {
          display: none;
        }
        .toogle_right {
          display: inline-block;
        }
      }
      .app-menu__item {
        //overflow: hidden;
        &:hover {
          overflow: visible;
          .app-menu__label {
            opacity: 1;
          }
        }
      }
      .app-menu__label {
        display: block;
        position: absolute;
        top: 0;
        left: 66px;
        min-width: 180px;
        padding: 12px 5px 12px 20px;
        margin-left: -3px;
        line-height: 1;
        opacity: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      header.app-header:before {
        margin-left: 0;
      }
    }
    .app-sidebar__toggle .toogle_right {
      display: none;
    }
  }
  .sidebar_logo_img {
    display: none;
  }
  .mobile_toggle_right {
    display: none;
  }
  .app-nav {
    flex: 1 0 auto;
  }
  .app-header__logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    background: #fff;
    border-right: 1px solid $primary-color;
    flex: 0 0 auto;
    display: block;
    width: 280px;
    height: 80px;
    margin-top: -15px !important;
    //background-color: transparent;
  }
  .app-header {
    padding-right: 30px;
  }
  .app_header_block {
    padding: 24px 0;
    a {
      margin-bottom: 15px;
    }
  }
  .sidenav-toggled {
    .sidebar-heading {
      visibility: hidden;
    }
    .footer_content {
      width: 100%;
      margin-left: 0;
    }
  }
  .footer_content {
    width: calc(100% - 300px);
    margin-left: 300px;
    box-shadow: 2px 2px 6px #000000;
    text-shadow: 2px 2px 4px #000000;
  }
  .sidebar_toggle_btn {
    margin-left: -23px;
    margin-top: -10px;
  }
}

.app_title {
  font-weight: 500;
  display: block;
  margin-top: 5px;
  span {
    font-size: 18px;
  }
  a {
    &:hover {
      color: #05367f;
    }
    &:focus {
      color: #05367f;
    }
  }
}

.left_Sidebar_Sec {
  position: fixed;
  top: 0;
  z-index: 2;
}
.app-nav-right {
  width: auto;
  margin-left: auto;
  display: inline-block;
  ul li {
    display: inline-block;
    margin-left: 22px;
    &:last-child {
      margin-right: 0 !important;
    }
  }
}
.app.sidenav-toggled {
  .app-sidebar {
    left: -230px;
  }
  .app-sidebar__overlay {
    visibility: hidden;
  }
  .app-header__logo {
    .small_logo {
      display: block;
      width: 28px;
    }
    .sidebar_logo_img {
      display: none;
    }
  }
}

a.app-menu__item img {
  vertical-align: middle;
}

.audit_item:hover {
  opacity: 0.5;
  transition: 0.3s ease-in-out 0.2s;
}
.mat-menu-panel {
  border-radius: 0 0 4px 4px !important;
  margin-top: 5%;
}
@media (max-width: 2502px) {
  .app-content {
    padding: 70px 15px 30px;
  }
}
@media (max-width: 1440px) {
  .app-content {
    padding: 70px 15px 30px;
  }
}

@media (max-width: 991px) {
  .app-content {
    padding: 100px 15px 30px 15px !important;
    margin-left: 0;
    padding: 0 15px;
  }
  .footer_content {
    width: 100%;
    margin-left: 0;
  }
  .mobile_toggle_right {
    display: block;
    i {
      font-size: 20px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .app_header_block {
    padding: 0;
    a {
      margin-bottom: 0;
    }
  }
  .sidebar_logo_img {
    display: block;
    border-radius: 8px;
  }
  .app_title {
    padding-left: 10px;
    margin-right: 5px;
  }
  .left_Sidebar_Sec {
    position: static;
  }
  .sidebar_toggle_btn {
    display: none;
  }
  .app-sidebar {
    float: left;
    position: static;
    height: 100vh;
    padding-top: 0;
  }
  .app-header {
    //padding: 12px 15px;
    width: 100% !important;
    border-bottom: 1px solid #cccccc;
  }
  .app_header_deshktop {
    display: none;
  }
  .left_sidebar_visible {
    overflow: hidden;
    .layer-drop {
      opacity: 1;
      visibility: visible;
    }
    .left_sidebar_wrapper {
      left: 0;
      opacity: 1;
      visibility: visible;
    }
  }
  .layer-drop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(2, 16, 36, 0.45);
    visibility: hidden;
    z-index: 5;
    transition: all 0.5s ease;
  }
  .left_sidebar_wrapper {
    position: fixed;
    left: -400px;
    top: 97px;
    width: auto;
    max-width: 100%;
    height: 100vh;
    overflow-y: auto;
    z-index: 5;
    opacity: 0;
    transition: all 0.5s ease;
    visibility: hidden;
    .cross-icon {
      position: absolute;
      cursor: pointer;
      color: $white;
      font-size: 15px;
      font-weight: normal;
      right: 0;
      width: 27px;
      height: 27px;
      line-height: 27px;
      text-align: center;
      outline: none;
      z-index: 9;
      &:hover {
        background: #05367f;
      }
      &:focus {
        background: #05367f;
      }
    }
  }
  .top_heaer_right {
    transition: all 0.3s;
    position: relative;
    //top: -7px;
  }
  .topheader_right_content {
    color: #000000;
    width: 100%;
    text-align: right;
    margin: auto;
    display: none;
    position: fixed;
    left: 0;
    right: auto;
    top: 60px;
    background: $white;
    border-bottom: 1px solid #cccccc;
    padding: 5px 24px 5px 5px;
  }
}

@media (max-width: 575px) {
  .app_title {
    margin-top: 7px;
    span a {
      display: none;
    }
  }
}

@media (max-width: 480px) {
  .app_title {
    font-size: 17px;
    span {
      font-size: 12px;
      font-size: 12px;
    }
  }
}

@media (max-width: 340px) {
  .app-header .app_title {
    font-size: 14px;
    padding-left: 6px;
  }
}
.app-sidebar {
  background: #fff;
}