//store form
.hl_edit_Storefront_upload{
  .avatar-upload {
    .avatar-preview {
      width: 330px;
      height: 270px;
      position: relative;
      display: inline-block;
      > div {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-repeat:no-repeat;
        background-position: center;
        background-size: cover;
      }
    }
  }
}
.hl_add_product_upload{
  border:1px solid $gray-400;
  text-align:center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  border-radius: 5px;
  -moz-border-radius:5px;
  -khtml-border-radius:5px;
  position: relative;
  min-height: 240px;
  .avatar-edit-camera{
    -webkit-box-flex: 50%;
    -moz-box-flex: 50%;
    -webkit-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    position:relative;
    &:after{
      content: '';
      border: 1px dashed #C9C9C9;
      height: 95px;
      position: absolute;
      right: 0;
      top: 30%;
    }
    a, button {
      display: inline-block;
      margin-bottom: 0;
      font-weight:$ft_light_300;
      color:$gray-400;
      text-align:center;
      cursor: pointer;
      text-decoration:none;
      position: absolute;
      left: 0;
      right: 0;
      top: 39%;
    }
  }
  .avatar-upload-wrapper{
    -webkit-box-flex: 50%;
    -moz-box-flex: 50%;
    -webkit-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    position:relative;
    .avatar-upload {
      position:absolute;
      left:0;
      right:0;
      top:39%;
      .avatar-edit {
        position:relative;
        z-index: 1;
        input {
          display: none;
          + label {
            display: inline-block;
            margin-bottom: 0;
            font-weight:$ft_light_300;
            color:$gray-400;
            text-align:center;
            cursor: pointer;
            text-decoration:none;
          }
        }
      }

      .avatar-preview {
        width: 120px;
        height: 65px;
        position:absolute;
        left:0;
        right:0;
        top: -10px;
        margin: 0px auto;
        display: inline-block;
        > div {
          width: 100%;
          height: 75px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background:transparent;
          background-repeat:no-repeat;
          background-position: center;
          background-size: cover;
          border-radius: 5px;
          -moz-border-radius:5px;
          -khtml-border-radius:5px;
        }
      }
    }
  }
}
.hl_edit_product_upload{
  text-align:center;
  .avatar-preview {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    border: solid 1px #4BC89C;
    margin-bottom: 5%;
  }
  .avatar-upload {
    position: relative;
    max-width: 100%;
    display:inline-block;
    .avatar-edit {
      position: absolute;
      right: 15px;
      z-index: 1;
      bottom: 22px;
      input {
        display: none;
        + label {
          display: inline-block;
          width: 55px;
          height: 55px;
          margin-bottom: 0;
          border-radius: 100%;
          background: $white;
          text-align:center;
          line-height:70px;
          border: 1px solid $gray-400;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.13);
          cursor: pointer;
        }
      }
    }
    .avatar-edit-camera{
      position: absolute;
      right: 85px;
      z-index: 1;
      bottom: 22px;
      a, button{
        display: inline-block;
        width: 55px;
        height: 55px;
        margin-bottom: 0;
        border-radius: 100%;
        background: $white;
        text-align:center;
        line-height:70px;
        border: 1px solid $gray-400;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.13);
        cursor: pointer;
      }
    }
    .avatar-preview {
      width: 330px;
      height: 270px;
      position: relative;
      display: inline-block;
      > div {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-repeat:no-repeat;
        background-position: center;
        background-size: cover;
      }
    }
  }
}
.avatar-upload {
  img {
    vertical-align: inherit !important;
  }
}
.hl_edit_product_form{
  padding-bottom:40px;
  text-align:center;
  form{
    display: grid;
  //  input{
  //    height:55px;
  //    border:1px solid $gray-400;
  //    font-size: 14px;
  //    font-weight:$ft_light_300;
  //    padding-left:20px;
  //    border-radius: 3px;
  //    -moz-border-radius:3px;
  //    -khtml-border-radius:3px;
  //    margin-top:10px;
  //    &:focus{
  //      outline:none;
  //    }
  //    &::-webkit-input-placeholder {
  //      color: $gray-400;
  //      font-style: italic;
  //    }
  //
  //    &:-ms-input-placeholder {
  //      color: $gray-400;
  //      font-style: italic;
  //    }
  //
  //    &::placeholder {
  //      color: $color-secondary;
  //      font-style: italic;
  //    }
  //  }
  //  textarea{
  //    border:1px solid $gray-400;
  //    font-size: 14px;
  //    font-weight:$ft_light_300;
  //    padding-left:20px;
  //    padding-top:15px;
  //    padding-bottom:15px;
  //    border-radius: 3px;
  //    -moz-border-radius:3px;
  //    -khtml-border-radius:3px;
  //    resize:none;
  //    font-family: "Montserrat", sans-serif;
  //
  //    margin-top:10px;
  //    &:focus{
  //      outline:none;
  //    }
  //    &::-webkit-input-placeholder {
  //      color: $gray-400;
  //      font-style: italic;
  //    }
  //
  //    &:-ms-input-placeholder {
  //      color: $gray-400;
  //      font-style: italic;
  //    }
  //
  //    &::placeholder {
  //      color: $color-secondary;
  //      font-style: italic;
  //    }
  //  }
  }
  .hl_edit_pro_price_field{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    input{
      float:left;
      width:50%;
      &:first-child{
        margin-right:5px;
      }
      &:last-child{
        margin-left:5px;
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer !important;
}
//store details page
.hl_buss_header {
  position: relative;
}
.hl_buss_header .pro_location_img {
  max-height: 265px;
  width: 100%;
}
.hl_buss_header .hl_product_back_btn {
  position: fixed;
  top: 0;
  left: 0;
}
.hl_buss_cont {
  padding-top: 30px;
  padding-bottom: 60px;
  background: #ffffff;
  border-top-left-radius: 15px;
  -moz-border-top-left-radius: 15px;
  -khtml-border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  -moz-border-top-right-radius: 15px;
  -khtml-border-top-right-radius: 15px;
  margin-top: -15px;
  position: relative;
}
.padding_lr {
  padding-left: 15px;
  padding-right: 15px;
}
.hl_buss_cont span {
  font-weight: 300;
  margin-top: 5px;
  display: block;
}
.hl_buss_cont p {
  font-weight: 300;
  margin-top: 15px;
}
.read_more {
  color: #4BC89C;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: underline;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.hl_footer_cont {
  height: 69px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
.hl_footer_cont:before {
  content: '';
  width: 26px;
  height: 26px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -khtml-border-radius: 100%;
  background: #C9C9C9;
  position: absolute;
  right: -13px;
  top: -29px;
}
.hl_footer_cont .hl_footer_border {
  width: 103%;
  border: 1px dashed #C9C9C9;
  position: absolute;
  left: -8px;
  right: 0px;
  top: -17px;
}
.btn-custom {
  float: left;
  width: 100%;
  padding: 15px 0;
  background: #4BC89C;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}
.hl_footer_cont:after {
  content: '';
  width: 26px;
  height: 26px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -khtml-border-radius: 100%;
  background: #C9C9C9;
  position: absolute;
  left: -13px;
  top: -29px;
}
//autocmplete style

.autocomplete-dropdown-container {
  text-align: left;
  position: absolute;
  width: 75%;
  border: solid 1px #eee;
  background: #fff;
  z-index: 10;
  border-radius: 5px;
  .suggestion-item, .suggestion-item--active {

    background-color: rgb(255, 255, 255);
    cursor: pointer;
    border-bottom: solid 1px #eee;
    padding: 7px;
    &:hover {
      background-color: #eee !important;
    }
  }
}
.img-fit-cover {
  object-fit: cover;
}
