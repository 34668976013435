.react-daterange-picker__wrapper {
  border: none;
  .react-daterange-picker__range-divider {
    padding: 5px;
  }
}
.text_count {
  color: #FD3398;
  text-align: center;
}

@media (max-width: 1080px) {
  .cal_block {
    width: 100%;
  }
}

/*** card css here ***/

.card-status {
  border: 0;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: all 0.3s linear;
  height: calc(100% - 20px);
}
.card-status:hover {
  box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.15);
}
.card-status .card-body {
  padding: 1.5rem 1.2rem;
}
.card-status .status-date {
  text-align: left;
  font-size: 14px;
}
.card-status .card-text {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
}
.card-status .card-status-title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}
.card-status .card-status-subtitle {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
}
.status-icon-wrap {
  margin-top: 8px;
  margin-bottom: 6px;
}
.status-icon {
  color: rgb(255, 255, 255);
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  padding: 12px;
  text-align: center;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}
.status-icon-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.status-icon-danger {
  color: #842029;
  background-color: #f8d7da;
}
.status-icon-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.status-icon-info {
  color: #055160;
  background-color: #cff4fc;
}
.status-icon svg {
  fill: currentColor;
  width: 24px;
  height: 24px;
  display: inline-block;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}