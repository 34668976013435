.lps_dsk_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: 100%;
  }
  .postBox {
    width: 100%;
  .on_boarding_wrp_spwn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
  }
}
.lps_inner_wrp_media {
    margin-top: 15px;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .lps_media {
    display: flex;
    align-items: flex-start;
}
.lps_fig.lps_fig_circle {
    width: 60px;
    height: 60px;
}
.lps_fig img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.lps_xl_view .main_feed_cont .lps_widgets_wrp {
    position: relative;
    margin: 30px 0 0;
    width: 500px;
}
.lps_xl_view .main_feed_cont .lps_widgets_wrp .feed_galary {
    position: relative;
    padding: 150px 0;
    min-height: 600px;
}
.main_feed_cont .lps_widgets_wrp .feed_galary {
    padding: 160px 0;
    text-align: center;
    margin: 0;
}
.main_feed_cont .lps_bg_prty img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.postBox a figure.feed_galary {
    height: auto !important;
    min-height: auto !important;
    background: none !important;
}
.lps_fig img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.lps_fig.lps_fig_circle {
    width: 60px;
    height: 60px;
}
.lps_xl_view .lps_dsk_list {
    display: flex;
    align-items: flex-end;
    width: 100%;
}
.lps_xl_view .main_feed_cont .lps_inner_wrp_media {
    width: 35%;
    padding-right: 50px;
}
.lps_fig.lps_fig_circle {
    width: 37px;
    height: 37px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
}
.lps_media_body {
    flex: 1 1;
}
.lps_xl_view .main_feed_cont .lps_widgets_wrp {
    position: relative;
    margin: 30px 0 0;
    width: 500px;
}
.main_feed_cont .lps_sm_shape {
    top: -27px;
}
.postBox a figure.feed_galary {
    height: auto !important;
    min-height: auto !important;
    background: none !important;
}
.lps_xl_view .main_feed_cont .lps_widgets_wrp {
    position: relative;
    margin: 30px 0 0;
    width: 500px;
}
.main_feed_cont .lps_sm_shape {
    top: -27px;
}
.lps_pos_rltv {
    position: relative;
}
.lps_inner_cont {
    padding: 30px 0;
}
.textContent article.lps_art a {
    display: block;
    min-height: 365px;
}
.lps_art p {
    color: #1a1a1a;
    letter-spacing: 0.16px;
    line-height: 20px;
}
.lps_neon_pink_border {
    border: 1px solid #FF6EC7;
}
.lps_inner_cont {
    padding: 30px 0;
}
.lps_inner_wrp {
    padding-left: 15px;
    padding-right: 15px;
}

.text_primary {
    color: #fd3398 !important;
}
.lps_sm_shape {
    width: 90px;
    height: 50px;
    position: absolute;
    top: 28px;
    left: 15px;
    background-image: url("../../assets/images/background/shap.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 93px;
    z-index: 10;
    &.lps_sm_shape1 {
      top: -24px;
    }
  }