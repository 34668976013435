.topheader_right_content {
  .notification {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    .notification-title {
      padding-top: 10px;
    }
    .notification-icon {
      fill: #676767;
    }
    .notification_circle {
      right: 12px;
      top: 15px;
    }
  }
  .profile-menu {
    .profile-icon {
      height: 40px;
      width: 40px;
      min-width: 40px !important;
      border-radius: 50% !important;
      img {
        height: auto;
        width: 100%;
        border-radius: 50%;
      }
    }
  }
}
.active {
  background-color: #00000008 !important;
  .unread-icon {
    color: #0747A6;
    font-size: 15px;
    height: 15px;
  }
}
#customized-menu .MuiListItemIcon-root {
  min-width: 30px;
}
.notification-menu-item {
  font-size: 12px;
  img {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
#notification-menu , #customized-menu {
  .MuiPopover-paper {
    top: 60px !important;
    margin-top: 0 !important;
  }
}
.app-header__logo {
  img {
    height: 100%;
    width: auto;
    padding-top: 20px;
  }
    span {
      vertical-align: middle;
      padding: 10px;
      color: #262933 !important;
    }
}
.sidenav-toggled {
  .app-header__logo {
    width: 75px;
  }
}