/*** post view ***/
.post-view-box {
  border: 1px solid #B3B3B3;
  padding: 30px;
}
.post-view-box .lps-mb-130 {
margin-bottom: 5%;
}
.post-view-sec {
    padding: 35px 15px;
    background-color: #fff;
  }
  .post-view-sec .adm-art h5 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    line-height: 24px;
  }
  .lps-link-block {
    display: block;
  }
  .adm-fig-post {
    width: 134px;
    height: 222px;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .adm-fig-post img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  input.input-border-bottom {
    font-size: 15px;
    line-height: 22px;
    color: #1a1a1a;
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid #b3b3b3;
    border-radius: 0;
    padding-left: 0;
    padding-top: 0;
    height: auto;
  }
  input.input-border-bottom:focus, input.input-border-bottom:active{
    background-color: transparent;
    outline: 0;
    box-shadow: none;
  }
  .tags-view-list {
    margin-bottom: 6px;
  }
  .tags-view-list .lps-link {
    margin-left: 15px;
  }
  .tags-view-list h5 {
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #1a1a1a;
    word-break: break-all;
  }
  .post-desc{
    word-break: break-all;
  }
  .font-regular {
    font-weight: 400 !important;
  }
  .text-underline {
    text-decoration: underline;
  }
  .tags-form-group {
    margin-bottom: 22px;
  }
  .adm-readmore-art {
    max-width: 134px;
    width: 100%;
  }
  .adm-readmore-art p {
    font-size: 15px;
    line-height: 22px;
    color: #1a1a1a;
  }
  .post-form-wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 134px 85px 1fr;
    grid-template-columns: 134px 1fr;
    grid-column-gap: 85px;
  }
  .btn-wrapper {
    margin-top: 74px;
  }
  .btn-wrapper .mw-260 {
    max-width: 360px;
    width: 100%;
    display: block;
    margin-top: 15px;
    margin-left: auto;
  }
  .btn-wrapper .adm-btn {
    font-weight: 400;
    line-height: 22px;
    color: #1a1a1a;
    padding: 7px 10px;
    border-radius: 0;
    box-shadow: none;
  }
  .adm-btn.btn-primary {
    background: #fd3398;
    border-color: #fd3398;
  }

  .adm-btn.btn-disabled {
    background: #c3b9be;
    border-color: rgb(187, 185, 178)398;
  }
  .adm-btn.btn-primary:hover,
  .adm-btn.btn-primary:focus,
  .adm-btn.btn-primary:active {
    background: #fb027f !important;
    border-color: #fb027f !important;
    box-shadow: none !important;
  }
  .btn-gray.adm-btn {
    border-color: #e1e1e1;
    background: #e1e1e1;
  }
  .adm-btn.btn-gray:hover,
  .adm-btn.btn-gray:focus,
  .adm-btn.btn-gray:active {
    background: #d3d2d2 !important;
    border-color: #d3d2d2 !important;
    box-shadow: none !important;
  }
  
  .btn-darkgray.adm-btn {
    border-color: #b3b3b3;
    background: #b3b3b3;
  }
  .adm-btn.btn-darkgray:hover,
  .adm-btn.btn-darkgray:focus,
  .adm-btn.btn-darkgray:active {
    background: #a7a4a4 !important;
    border-color: #a7a4a4 !important;
    box-shadow: none !important;
  }
  
  .post-view-sec .lps-table thead th {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    border-bottom: 1px solid #dee2e6;
    border-top: 0;
    padding: 15px 0;
  }
  .post-view-sec .lps-table tbody .tag-list-active td {
    font-weight: 500;
    color: #1a1a1a;
  }
  .post-view-sec .lps-table tbody .tag-list-dactive td {
    color: #838296;
  }
  .post-view-sec .lps-table tbody td {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding: 20px 0;
  }
  .post-view-sec .lps-table .adm-btn {
    margin-left: 15px;
    border-radius: 0;
    padding: 6px 15px;
    min-width: 124px;
    letter-spacing: 0;
  }
  span.font_weight_M {
    font-weight: 500;
  }
  .text_primary {
    color: #fd3398 !important;
  }
  .lps-link {
    font-weight: 500;
    color: #fd3398;
    cursor: pointer;
  }
  .mouse_cursor {
    cursor: pointer;
  }
  .data-list-filter {
    margin: 0;
    padding: 10px 6px;
    list-style: none;
    width: 100%;
    background: #1A1A1A;
    border: 1px solid #FFFFFF;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 210px;
    overflow-y: auto;
    position: relative;
    display: block;
    top: 26px;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.data-list-filter li {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #fff;
  text-align: left;
}
/* //hasgtag  */
.data-list-filter::-webkit-scrollbar {
  width: 5px;
}
.data-list-filter::-webkit-scrollbar-track {
  background: #1A1A1A; 
}
.data-list-filter::-webkit-scrollbar-thumb {
  background: #FD3398; 
}
.data-list::-webkit-scrollbar-thumb:hover {
  background: #FD3398; 
}
.filter-wrapper {
  position: relative;
}
.btn-wrapper-btwn a.btn {
  margin-left: 20px;
  border-radius: 0;
  min-width: 275px;
}
.btn-wrapper-btwn {
  text-align: right;
  margin-bottom: 100px;
}
.showUserListWrapper {
  position: absolute;
  background: #fff;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%);
  border-radius: 5px;
  padding: 10px 12px;
  min-width: 130px;
  max-height: 159px;
  overflow-y: auto;
}
ul.showUserList {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.showUserList li {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 5px;
}
.viewPostDetailsModal .modal-title {
  margin-bottom: 20px !important;
  font-size: 20px;
}
.lps-modal.suggest-modal .modal-body {
  padding: 20px;
}
.viewPostDetailsModal figure.postFig {
  text-align: center;
}
.viewPostDetailsModal .postFig img {
  max-width: 100%;
}
.viewPostDetailsModal .postDescriptions {
  font-size: 13px;
  overflow-y: auto;
}
.viewPostDetailsModal .close-icon-wrapper {
  text-align: right;
  margin-bottom: 20px;
}
.viewPostDetailsModal .postDescriptionsFull {
  max-height: 405px;
  
}
.viewPostDetailsModal .postDescriptionsHalf {
  max-height: 205px;
}
.close-link {
  font-size: 18px;
  margin: 0;
  padding: 0;
}

  @media screen and (min-width: 992px) {
    .add-post-form .col-sm-6 {
      padding-left: 50px;
    }
    .lps-modal.viewPostDetailsModal .modal-dialog {
      max-width: 890px;
    }
  }
  @media screen and (max-width: 991px) {
    .post-form-wrapper {
      -ms-grid-columns: 134px 40px 1fr;
      grid-column-gap: 40px;
    }
  }
  /*** post view ***/